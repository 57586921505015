<template>
  <div class="additional-exam-button-wrapper">
    <b-button
      v-if="canAddButton"
      variant="outline-dark"
      @click="createNewAdditionalExam"
    >
      Adicionar exame complementar
    </b-button>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import { registerAdditionalExamModule } from '@/store/registerModule'
import { gotToOption } from '@/utils/attendanceHelper'

export default {
  computed: {
    ...mapState({
      attendance: state => state.attendance.attendance,
      canEdit: state => state.attendance.canEdit,
      customFormEdit: state => state.attendance.customFormEdit,
      selectedForm: state => state.attendance.form.selected,
    }),
    canAddButton() {
      return this.canEdit && ['ATTENDANCE', 'EXAM'].includes(this.attendance?.type) || (this.customFormEdit && ['ATTENDANCE', 'EXAM'].includes(this.selectedForm?.type))
    }
  },
  methods: {
    ...mapActions('attendance/menu', ['addAdditionalExamMenu']),
    ...mapActions('attendance/form', ['addAdditionalExamForm', 'handleActiveModule', 'updateForms']),
    createNewAdditionalExam() {
      const time = new Date().getTime()
      const formId = `exame-personalizado-${time}`
      registerAdditionalExamModule(formId, 'Exame personalizado')
      this.handleActiveModule(formId)
      
      setTimeout(() => gotToOption(formId), 500)

      if (this.customFormEdit) {
        this.updateForms([{
          id: this.$store.state.attendance.form[formId].id,
          name: this.$store.state.attendance.form[formId].name,
          opened: this.$store.state.attendance.form[formId].opened,
          showing: this.$store.state.attendance.form[formId].showing,
        }])
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.additional-exam-button-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 15px 15px 20px 15px;

  button {
    border: 2px solid var(--type-active) !important;
    font-weight: 700;
    border-radius: 8px;
    font-size: min(1.2vw, 14px);

    &:hover {
      background-color: transparent;
      color: var(--type-active);
    }
  }
}
</style>
